import cx from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef, useState } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import ArrowRight from '../assets/images/arrow-outline-right.svg';
import ArrowTop from '../assets/images/arrow-top.svg';
import Arrow from '../assets/images/arrow.svg';
import Face from '../assets/images/face-min.png';
import Fire from '../assets/images/fire.svg';
import styles from '../assets/scss/design.module.scss';
import ImageCard from '../components/ImageCard';
import TiltComponent from '../components/TiltComponent';
import { GSAP, Mindlyf, ProdJamThumb, GlanceRedesign, PixelRedesign, HotstarUI, PitchDeck, Rahul, Rishabh, Saurabh, Vishal, LoginDesign, LeadApp } from '../resources';
import {isMobile} from 'react-device-detect';

gsap.registerPlugin(ScrollTrigger);
const tl = gsap.timeline();

function Home() {

	const [index, setIndex] = useState(0);
	const TEXTS = ['Hello', 'নমস্কার', 'नमस्ते', 'ನಮಸ್ಕಾರ', 'నమస్కారం', 'നമസ്കാരം', 'வணக்கம்', 'ନମସ୍କାର'];
	const [currentTestimonial, setCurrentTestimonial] = useState(0);
	const testimonialRef = useRef(null);
	const blogHeaderRef = useRef(null);
	const nameRef = useRef(null);
	const horizontalSectionRef = useRef(null);
	const sliderIntervalRef = useRef(null);
	const imageRef = useRef(null);
	const [stopAutoSlider, setStopAutoSlider] = useState(false);
	const testimonials = [
		{
			text: '"Arnab is a highly innovative and detail orientated person, which is reflected in the success of all his project endeavors. He is an innovative thinker and his contributions were essential to the success of the projects we delivered together. Arnab is terrific with challenging assignments, tight deadlines and works well under adverse conditions. I love to have the opportunity of  working with him."',
			image: Vishal,
			position: "Associate Vice President - Product @ Kotak Mahindra Bank",
			name: 'Vishal Tiwari',
			link: 'https://www.linkedin.com/in/vtvishaltiwari/'
		},
		{
			text: '"Arnab consistently demonstrated exceptional skills in product management, with a deep understanding of customer needs, market trends, and competitive analysis. He was good in translating these insights into strategic product plans, designs, working prototypes and roadmaps, which resulted in the successful launch of several innovative products that generated significant revenue and market share growth for our organisation."',
			image: Rahul,
			position: "Product @ MetricStream",
			name: 'Rahul Prakash',
			link: 'https://www.linkedin.com/in/rahulprk/'
		},
		{
			text: '"I have worked with Arnab on my startup, ATG. His passion for working towards growth and making a dent in the universe is applaudable. I have worked with him on marketing, finance and other startup related tasks. I have found him to be responsible and hard-working. Given a chance, I would love to work with Arnab full-time in the future."',
			image: Saurabh,
			position: "Founder @ ATG",
			name: 'Saurabh Bassi',
			link: 'https://www.linkedin.com/in/saurabhbassi/'
		},
		{
			text: '"Arnab has profound knowledge as an entrepreneur and he is a team leader. His emotional intelligence and never give up attitude makes him stand out from the competition. His ability to aim high and work under pressure is something very rare for a professional of his age. It was a privilege working with him and he is a real asset to the team."',
			image: Rishabh,
			position: "Customer Success Manager @ Pepper Content",
			name: 'Rishabh Agarwal',
			link: 'https://www.linkedin.com/in/rishabh536/'
		},
	];
		
	tl.from(".line span", 1.8, {
		y: 100,
		ease: "power4.out",
		delay: 1,
		skewY: 7,
		stagger: {
		amount: 0.3
		}
	});

	React.useEffect(() => {
		const intervalId = setInterval(
		  () => setIndex((index) => index + 1),
		  2000, // every 3 seconds
		);
		return () => clearTimeout(intervalId);
	}, []);

	useEffect(() => {
		if (!stopAutoSlider) {
			sliderIntervalRef.current = setInterval(() => {
			changeTestimonial(
				currentTestimonial === testimonials.length - 1 ? 0 : currentTestimonial + 1
			);
			}, 5000);
		}

		return () => {
			clearInterval(sliderIntervalRef.current);
		};
	}, [stopAutoSlider, currentTestimonial, testimonials.length]);

	useEffect(() => {
		gsap.fromTo(
			testimonialRef.current,
			{ opacity: 0 },
			{ opacity: 0.7, duration: 0.5 }
		);
		gsap.fromTo(
			nameRef.current,
			{ opacity: 0 },
			{ opacity: 0.7, duration: 0.5 }
		);
	}, [currentTestimonial]);

	useEffect(() => {
		const horizontalSection = horizontalSectionRef.current;
	
		const horizontalScrollTimeline = gsap.timeline({
		  scrollTrigger: {
			trigger: horizontalSection,
			start: isMobile ? 'top 30%' : 'top 22%',
			end: () => `+=${horizontalSection.offsetWidth+isMobile ? 1000:70}`,
			pin: true,
			scrub: true,
			anticipatePin: 1,
		  },
		});
	
		horizontalScrollTimeline.to(horizontalSection, {
			x: () => -(horizontalSection.scrollWidth + 70 - horizontalSection.offsetWidth),
			ease: 'none',
		});
	}, []);

	useEffect(() => {
		const blogSection = blogHeaderRef.current;
	
		const blogScrollTimeline = gsap.timeline({
		  scrollTrigger: {
			trigger: blogSection,
			start: 'bottom bottom',
			end: 'top top',
			scrub: true,
			anticipatePin: 1,
		  },
		});
	
		blogScrollTimeline.to(blogSection, {
			x: () => -(blogSection.scrollWidth - blogSection.offsetWidth - isMobile ? 1000 : 0),
			ease: 'none',
		});
	}, []);

	const changeTestimonial = (newIndex) => {
		gsap
		.to(imageRef.current, { width: 0, opacity: 0, duration: 0.5, ease: 'Power2.easeInOut' })
		  .then(() => {
			setCurrentTestimonial(newIndex);
			gsap.to(imageRef.current, {
			  width: '100%',
			  opacity: 0.5,
			  duration: 0.5,
			  ease: 'Power2.easeInOut',
			});
		  });
		gsap.to(testimonialRef.current, { opacity: 0, duration: 0.5 }).then(() => {
			setCurrentTestimonial(newIndex);
		});
		gsap.to(nameRef.current, { opacity: 0, duration: 0.5 }).then(() => {
			setCurrentTestimonial(newIndex);
		});
	};


	const handlePrev = () => {
		clearInterval(sliderIntervalRef.current);
		setStopAutoSlider(true);
		changeTestimonial(
		  currentTestimonial === 0 ? testimonials.length - 1 : currentTestimonial - 1
		);
	};
	
	const handleNext = () => {
		clearInterval(sliderIntervalRef.current);
		setStopAutoSlider(true);
		changeTestimonial(
			currentTestimonial === testimonials.length - 1 ? 0 : currentTestimonial + 1
		);
	};

	return (
		<div>
			<div className={styles.radial_circle}></div>
			<div className={styles.radial_circle_top}></div>
			<div className={styles.banner_section}>
				<div className={styles.hello_text}>
					<TextTransition text={TEXTS[index % TEXTS.length]} springConfig={presets.default} />
				</div>
				<div className={styles.about_description}>
					<div><img src={Face} alt='face'/></div>
					I'm <strong>arnab chaudhuri.</strong> I help brands build impactful <span className={styles.underline}>products</span>. I’m always open to talk about new ideas and technology over a cup of coffee. This page speaks about me and my work.<br/>Scroll down to know more...
				</div>
				<div className={styles.scroll_container}>
					<div className={styles.scroll_box}>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>

			<div id='design' className={styles.design_container}>
				<div className={styles.design_title}>I love to play with pixels</div>
				<div ref={horizontalSectionRef} className={cx(styles.design_slider, styles.ml_60)}>
					<ImageCard link={"https://www.behance.net/gallery/170144433/Revisiting-Disney-Hotstar"} title={"Hotstar Redesign"} desc={"Re-imagining India's largest OTT platform. Features to increase the user engagement and content discovery."} image={HotstarUI}/>

					<ImageCard link={"https://dribbble.com/shots/21407824-Lead-management-app"} title={"Lead Mentor"} desc={"Lead management app for a student counselling company. The app features integrations with 3rd party solutions, dialers and a lot more."} image={LeadApp}/>

					<ImageCard link={"https://www.figma.com/file/LtWSyGpPIDYPOgduk08N0t/Pitch-Template?node-id=9%3A7"} title={"Pitch Figma Template"} desc={"A free template which eases the painstaking effort put into designing slides for a pitch deck."} image={PitchDeck}/>

					<ImageCard link={"https://dribbble.com/shots/21060684-Login-Screen"} title={"Login screen"} desc={"Designing the login and sign-up screen for the configuration dashboard of a client."} image={LoginDesign}/>
					
					<ImageCard link={"https://www.behance.net/gallery/122187195/Glance-Product-Improvements"} title={"A take on Glance"} desc={"Trying to supercharge Glance, a provider of content-based services on the lock screen of smartphones."} image={GlanceRedesign}/>

					<ImageCard link={"https://dribbble.com/shots/21406006-Google-Pixel-Website-Redesign"} title={"The Dark Side"} desc={"A sleek redesign of Google Pixel Earbuds! This concept emphasizes a modern and minimalist look."} image={PixelRedesign}/>

				</div>
				<a className={styles.button_minimal} href={'https://dribbble.com/arnab15'} target="_blank" rel="noreferrer">Liking this? Check out some more designs<img alt="arrow" src={Arrow}/></a>
			</div>

			<div id="tech" className={styles.code_container}>
				<div className={styles.code_columns}>
					<div>
						<div className={styles.code_card1}>
							<div className={styles.glitch}data-text="Bringing ideas to <life/>">Bringing ideas to <span>{'<life/>'}</span></div>
							<div className={styles.sub_text}>one code snippet at a time</div>
						</div>
						<a href="https://demo.omnicuris.com/" target='_blank' rel='noreferrer'><div className={cx(styles.code_card, styles.code_card2)}>
							<div className={styles.flex_apart}>
								<div>
									<div className={styles.code_title}>GSAP Animation</div>
									<div className={styles.code_sub_text}>Demo of Omnicuris' product using scroll-triggered animations from the GSAP framework.</div>
								</div>
								<img className={styles.arrow_right} src={ArrowTop} alt='icon'/>
							</div>
							<img className={styles.code_img} src={GSAP} alt='card 1'/>
						</div></a>
					</div>
					<div>
						<a href="https://github.com/arnab1510/Collaboration-app-React-boilerplate" target='_blank' rel='noreferrer'><div className={cx(styles.code_card, styles.code_card3)}>
							<div className={styles.flex_apart}>
								<div>
									<div className={styles.code_title}>Collaboration App Boilerplate</div>
									<div className={styles.code_sub_text}>Open source frontend code for an interface to prepare product roadmaps, use prioritization frameworks, store PRDs, and communicate with the team efficiently.</div>
								</div>
								<img className={styles.arrow_right} src={ArrowTop} alt='icon'/>
							</div>
							<img className={styles.code_img} src={ProdJamThumb} alt='card 1'/>
						</div></a>
					</div>
					<div>
						<a href="https://mindlyf-web.vercel.app/" target='_blank' rel='noreferrer'><div className={cx(styles.code_card, styles.code_card2)}>
							<div className={styles.flex_apart}>
								<div>
									<div className={styles.code_title}>MindLyf</div>
									<div className={styles.code_sub_text}>A platform for assisting people with mental health issues. Spanning across a plethora of services, this packs in a design that stands out from the rest.</div>
								</div>
								<img className={styles.arrow_right} src={ArrowTop} alt='icon'/>
							</div><img className={styles.code_img} src={Mindlyf} alt='card 1'/>
						</div></a>
					</div>
					<div className={styles.gridSpace}>
						<div className={cx(styles.code_card, styles.code_card4)}>
							<div className={styles.code_title}>Recruitly</div>
							<div className={styles.code_sub_text}>Helping candidates upskill and prepare for interviews using AI. Assisting companies to hire the right talent, faster!</div>
							<div className={styles.coming_soon}><img src={Fire} alt='fire'/>COMING SOON</div>
						</div>
						<div className={cx(styles.code_card, styles.code_card5)}>
							<div className={styles.code_title}>Notification Kit</div>
							<div className={styles.code_sub_text}>Curated set of in app and web notifications free to be used in your next project. Includes snackbars, in-app popups, toasts, and a host of other formats.</div>
							<div className={styles.coming_soon}><img src={Fire} alt='fire'/>COMING SOON</div>
						</div>
					</div>
				</div>
			</div>

			<div id="product" className={styles.blog_container}>
				<div ref={blogHeaderRef} className={styles.scroll_head}>
					<span>THOUGHTS</span>
					<span>ARTICLES</span>
					<span>BLOGS</span>
					<span>PRODUCT</span>
					<span>THINKING</span>
				</div>
				<div className={styles.blog_slider}>
					<TiltComponent>
						<a href='https://arnab15c.medium.com/our-approach-to-identify-specialities-of-doctors-183f5a5fa163' target='_blank' rel='noreferrer'><div className={styles.blog_card}>
							<div className={styles.blog_title}>Increasing content relevance</div>
							<div className={styles.blog_desc}>This article throws light on the changes we underwent at Omnicuris to suggest content to the users with a higher relevance, crunch more data for intelligent locations, and drive more engagement.</div>
							<div className={styles.read_more}>Read more<img src={ArrowTop} alt='icon'/></div>
						</div></a>
					</TiltComponent>
					<TiltComponent>
						<a href='https://arnab15c.medium.com/project-atom-part-1-cab543fae80' target='_blank' rel='noreferrer'><div className={styles.blog_card}>
							<div className={styles.blog_title}>Building Project Atom - Part 1</div>
							<div className={styles.blog_desc}>Diving deep into the massive revamp of the entire Omnicuris platform, and building the design system from ground up across web and app platforms.</div>
							<div className={styles.read_more}>Read more<img src={ArrowTop} alt='icon'/></div>
						</div></a>
					</TiltComponent>
					<TiltComponent>
						<a href='https://arnab15c.medium.com/back-to-basics-vol-1-building-our-notification-engine-244d52adb272' target='_blank' rel='noreferrer'><div className={styles.blog_card}>
							<div className={styles.blog_title}>Building our notification engine</div>
							<div className={styles.blog_desc}>After considerable work and experience using some of the popular marketing automation softwares, here are my thoughts on how to set-up an intelligent and fool-proof marketing engine.</div>
							<div className={styles.read_more}>Read more<img src={ArrowTop} alt='icon'/></div>
						</div></a>
					</TiltComponent>
				</div>
			</div>

			<div className={styles.review_container}>
				<div className={styles.review_head}>
					<div className={styles.radial_circle_footer}></div>
					<div className={styles.review_header}>Folks I have worked with</div>
				</div>
				<div className={styles.review_slider}>
					<div className={styles.review_item}>
						<div className={styles.review_content}>
							<p ref={testimonialRef}>{testimonials[currentTestimonial].text}</p>
							<div className={styles.review_footer}>
								<a href={testimonials[currentTestimonial].link} target='_blank' rel='noreferrer'><div ref={nameRef} className={styles.review_footer_left}>
									<div className={cx(styles.underline, styles.review_name)}><span>{testimonials[currentTestimonial].name}</span><img src={ArrowTop} alt='icon'/></div>
									<div className={styles.review_company}>{testimonials[currentTestimonial].position}</div>
								</div></a>
								<div className={styles.slider_arrow}>
									<img onClick={handlePrev} src={ArrowRight} alt='arrow'/>
									<img onClick={handleNext} src={ArrowRight} alt='arrow'/>
								</div>
							</div>
						</div>
						<div style={{ position: 'relative', overflow: 'hidden' }}>
							<img className={cx(styles.review_img)} ref={imageRef} src={testimonials[currentTestimonial].image} alt='profile'/>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.footer_container}>
				<div>Thank you</div>
				<div>© 2023 All rights reserved.</div>
			</div>
		</div>
	);
}

export default Home;
