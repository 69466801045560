import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Dribbble from '../assets/images/dribbble.svg';
import LinkedIn from '../assets/images/linkedin.svg';
import Mail from '../assets/images/mail.svg';
import Medium from '../assets/images/medium.svg';
import styles from '../assets/scss/design.module.scss';

function Header() {

    const [scrollClass, setScrollClass] = useState('');

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setScrollClass(true);
        } else {
            setScrollClass(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

	return (
        <div className={styles.flex_center}>
            <div className={cx(styles.header_section, `${scrollClass ? styles.header_small : ''}`)}>
                <div className={styles.header_left}>
                    <a href='#home'><div className={styles.name_logo}>arnab.</div></a>
                    <a className={styles.hide_mobile} href='#design'><span>Design</span></a>
                    <a className={styles.hide_mobile} href='#tech'><span>Technology</span></a>
                    <a className={styles.hide_mobile} href='#product'><span>Product</span></a>
                </div>
                <div className={styles.social_icons}>
                    {/* <a className={styles.header_link} target="_blank" href='https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/Arnab_resume.pdf'><span>Download Resume</span></a> */}
                    <a target="_blank" href='https://www.linkedin.com/in/arnab15c/' rel='noreferrer'><img src={LinkedIn} alt='linkedin'/></a>
                    <a rel='noreferrer' target="_blank" href="https://dribbble.com/arnab15"><img src={Dribbble} alt='dribbble'/></a>
                    <a rel='noreferrer' href="mailto:arnab.15c@gmail.com"><img style={{height: '14px'}} src={Mail} alt='mail'/></a>
                    <a rel='noreferrer' target="_blank" href="https://medium.com/@arnab15c"><img style={{height: '14px'}} src={Medium} alt='medium'/></a>
                </div>
            </div>
        </div>
    );
}

export default Header;
