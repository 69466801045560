import React from 'react';
import AppRoutings from './AppRoutings';
import AnimatedCursor from "react-animated-cursor";

function App() {
	return (
		<div id="home" style={{backgroundColor: "#0D1117"}}>
			<AnimatedCursor 
				color='130, 129, 127'
				outerScale={2}
				innerSize={14}
     	 		outerSize={30}
			/>
			<AppRoutings />
	  </div>
	);
}

export default App;
