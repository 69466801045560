export const HotstarUI = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/hotstar-3.png';
export const DesignLogos = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/design_apps-min.png';
export const PitchDeck = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/pitch.png';
export const ProjectAtom = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/project_atom-min.png';
export const IntelliReco = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/intelligent_reco.png';
export const GlanceRedesign = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/glance_thumb-min.png';
export const PixelRedesign = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/pixel-redesign.png';
export const NotificationKit = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/notification_kit_thumb-min.png';
export const Mindlyf = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/mindlyf-thumb.png';
export const GSAP = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/gsap-thumb.png';
export const ProdJamThumb = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/prodjam-thumb.jpg';
export const Darkside = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/darkside-min.png';
export const ProdJam = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/ProdJam.png';
export const LoginDesign = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/login-ui.png';
export const LeadApp = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/lead-app.png';

//Stacks
export const AWSlogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/aws_logo-min.png';
export const FlutterLogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/flutter_logo-min.png';
export const ReactLogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/react_logo-min.png';
export const SassLogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/sass_logo-min.png';
export const NodeLogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/node_js_logo-min.png';
export const MongoLogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/MongoDB_logo-min.png';

//Reviews
export const Rishabh = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/rishabh-2.jpg';
export const Vishal = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/vishal-2.jpg';
export const Saurabh = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/saurabh.jpg';
export const Rahul = "https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/rahul.png";

//Mobile Logos
export const FigmaMobile = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/mobile_logos/Figma-logo-min.png';
export const ProtopieLogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/mobile_logos/protopie-min.png';
export const XDlogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/mobile_logos/xd-min.png';
export const InvisionLogo = 'https://arnabc-portfolio.s3.ap-south-1.amazonaws.com/mobile_logos/invision-min.png';