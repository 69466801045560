import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';

export default function AppRoutings() {
	return (
		<Router>
			<Header />
			<Switch>
				<Route exact path='/' component={Home} />
				{/* <Route path='*' component={PageNotFound} /> */}
			</Switch>
			{/* <Footer /> */}
		</Router>
	);
}
