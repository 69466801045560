import React from 'react';
import Tilt from 'react-parallax-tilt';

function TiltComponent(props) {
  const { children } = props;

  return (
    <Tilt glareEnable={true} glareBorderRadius='18px' glarePosition="all" glareColor='#0024c4fb' tiltMaxAngleX={4} tiltMaxAngleY={4} glareMaxOpacity={0.3}>
      {children}
    </Tilt>
  );
}

export default TiltComponent;
