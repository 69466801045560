import React from 'react';
import ArrowRight from '../assets/images/arrow-outline-right.svg';
import cx from 'classnames';
import styles from '../assets/scss/design.module.scss';

function ImageCard(props) {
  const { link, title, desc, image, hoverText } = props;

  return (
    <div className={styles.cardLight}>
      <a className={styles.image_link} href={link} target="_blank" rel="noreferrer">
        <div className={styles.hover_content}>
          <p className={styles.hover_text}>{hoverText===undefined?'View design':hoverText}</p>
          <img src={ArrowRight} alt='arrow'/>
        </div>
        <img className={styles.design_thumb} src={image} alt="thumbnail"/>
      </a>
      <div className={cx(styles.text_content)}>
        <a href={link} target="_blank" rel="noreferrer"><div className={styles.title}>{title}</div></a>
        <p className={styles.desc}>{desc}</p>
      </div>
    </div>
  );
}

export default ImageCard;
